body {
    margin: 0;
    font-family: 'Arimo', Sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    a{ text-decoration: none;}

    p{ margin: 0}

    .titulo-1{
        font-size: 1.4em;
        font-weight: 700;
        margin: 10px 0;
    }
    .subtitulo-1{
        font-size: 1.2em;
        font-weight: 700;
        margin: 10px 0;
    }

    .titulo-2{
        font-size: 1.1em;
        margin: 10px 0;
    }
    .subtitulo-2{
        font-size: 1.1em;
        margin: 10px 0;
        font-weight: 400;
    }

    .scroll-custom{
        &::-webkit-scrollbar{
            -webkit-appearance: none ! important;
        }
        
        &::-webkit-scrollbar:vertical {
            width: 8px ! important;
        }
        
        &::-webkit-scrollbar-button:increment,*::-webkit-scrollbar-button {
            display: none ! important;
        } 
        
        &::-webkit-scrollbar:horizontal {
            height: 5px ! important;
            
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: #0D2338 ! important;
            border-radius: 100px ! important;
            border: 1px solid #0D2338 ! important;
        }
        
        &::-webkit-scrollbar-track {
            border-radius: 10px ! important;  
        }
    }

    .boton-continuar{
        font-weight: bold;
        font-size: 16px;
        margin-top: 20px;
        outline: none;
        text-decoration: none;
        border-radius: 8px;
        padding: 12px 0px; border: none;
        
    }
    
    .boton-disabled{
        background-color: #B4BBC1;
        color: #777778;
    }
    
    .boton-enabled{
        background-color: #B7EB06;
        color: #0D2338;
        cursor: pointer;
    }
}

@font-face {
    font-family: 'Arimo';
    src: url('.././Fonts/static/Arimo-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}
  


/**Conjunto para FLEX-BOX----------------------------------*/

.direction-row{
    display: flex; flex-direction: row;
}

.direction-column{
    display: flex; flex-direction: column;
}

.justify-between{
    justify-content: space-between;
}

.justify-around{
    justify-content: space-around;
}

.justify-start{
    justify-content: flex-start;
}

.justify-center{
    justify-content: center;
}

.justify-end{
    justify-content: flex-end;
}

.align-between{
    align-items: space-between;
}

.align-around{
    align-items: space-around;
}

.align-start{
    align-items: flex-start;
}

.align-end{
    align-items: flex-end;
}

.align-center{
    align-items: center;
}
.flex-wrap{
    flex-wrap: wrap;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }

/**------------------------------------*/