.general-body-results{
    width: 100%; height: 100vh;
    overflow-x: hidden;
    background-color: #0D2338;


    header{
        position: absolute;
        top: 0; left: 0;
        z-index: 1000;
        width: 100vw;
        height: 70px;
        display: flex; flex-direction: row; justify-content: space-between; align-items: center;
    
        .img-logo{
            height: 56px;
            object-fit: contain;
            margin: 20px;
            cursor: pointer;
        }
    
        .img-arrow{
            height: 45px;
            object-fit: contain;
            cursor: pointer;
        }
        svg{
            cursor: pointer;
        }
    }
    

    .espacio-input{
        display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;
        position: relative;
        margin: 0px 10px;
        width: 260px;
        p{
            margin-bottom: 3px !important;
        }
        .titulo-2{
            font-size: 18px;
            width: 260px;
        }
        .input{
            width: 260px !important; height: 50px;
            border-radius: 8px;
            outline: none;
            text-decoration: none;
            border: none; padding-left: 10px;
        }
        .input-select{
            width: 272px !important; height: 50px;
            border-radius: 8px;
            outline: none;
            text-decoration: none;
            border: none; padding-left: 10px;
        }
    
        .input-error{
            width: 398px; height: 50px;
            border-radius: 8px;
            outline: none;
            text-decoration: none;
            border: 2px solid #B55348;
            padding-left: 10px;
        }
    
        .texto-error{
            font-size: 14px;
            color: #B55348;
            width: 398px;
            margin-top: 0px;
        }
    
        .ojo{
            position: absolute;
            right: 15px; bottom: 20px;
            width: 16px; object-fit: contain;
            cursor: pointer;
        }
    
        .ojo-error{
            position: absolute;
            right: 15px; bottom: 55px;
            width: 16px; object-fit: contain;
            cursor: pointer;
        }
        .ojo-confirm-error{
            position: absolute;
            right: 15px; bottom: 41px;
            width: 16px; object-fit: contain;
            cursor: pointer;
        }
    }

    .upload-img{
        position: absolute;
        width: 25px;
        object-fit: contain;
        top: 30px;
    }

    .boton-documentos{
        display: flex;
        width: 250px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        background-color: #0D2338;
        border-radius: 8px;
        border: 1px solid var(--light, #FBFBFB);
        color: var(--light, #FBFBFB);
        font-size: 18px;
        font-weight: 700;
        margin: 20px 25px 20px 0px;
        cursor: pointer;

        p{
            margin-left: -25px;
        }

    }

    .boton-continuar{
        width: 570px; height: 42px;
    }

}

.espacio-titulo{
    margin-top: 125px;
}