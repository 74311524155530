.componente-vector{
    width: 55vw; height: 100vh;
    position: relative;

    p{ color: white; }

    .vector-fondo-izq{
        position: absolute;
        top: 0; left: -5vw;
        height: 100vh;
        width: 55vw; 
    }
    .vector-fondo-der{
        position: absolute;
        top: 0; right: -5vw;
        height: 100vh;
        width: 55vw;
    }
    .espacio-info-izq{
        width: 40vw;
        margin-left: -5vw;
    }
    .espacio-info-results{
        width: 40vw;
        margin-left: 15vw;
    }
    .espacio-info-der{
        width: 40vw;
        margin-right: -5vw;
    }
    .titulo-1{
        font-size: 1.4em;
        font-weight: 700;
    }

    .titulo-2{
        font-size: 1.1em;
    }
    
}

