.general-body{
    width: 100%; height: 100vh;
    overflow-x: hidden;
    background-color: #0D2338;

    header{
        position: absolute;
        top: 0; left: 0;
        z-index: 2;
        width: 100vw;
        height: 70px;
        display: flex; flex-direction: row; justify-content: flex-end; align-items: center;
    
    
        .img-arrow{
            height: 45px;
            object-fit: contain;
            cursor: pointer;
        }
        svg{
            cursor: pointer;
        }
    }
}

.componente-mitad{
    width: 100vh;
    p{ color: white; }


    .formulario-inicio{
        .titulo-2{
            font-size: 18px;
            width: 400px;
        }

        .espacio-titulo{
            width: 100%;

            .titulo-img{
                width: 183px; height: 63px;
            }

            .logo-img{
                width: 72px; height: 63px;
            }
        }

        .espacio-subtitulo{
            display: flex; flex-direction: row; justify-content: flex-start; align-items: center;
            width: 100%;

            .flecha-atras{
                width: 24px; height: 24px;
                margin: 10px;
                cursor: pointer;
            }
            
        }

        .espacio-input{
            display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;
            position: relative;
            p{
                margin-bottom: 3px !important;
            }
            .titulo-2{
                font-size: 18px;
                width: 400px;
            }
            .titulo-2-error{
                font-size: 18px;
                width: 400px;
                color: #B55348;
            }
            .texto-error{
                font-size: 14px;
                width: 400px;
                color: #B55348;
            }

            .input{
                width: 400px; height: 50px;
                border-radius: 8px;
                outline: none;
                text-decoration: none;
                border: none; 
                padding-left: 10px;
            }
            .input-error{
                width: 400px; height: 50px;
                border-radius: 8px;
                outline: none;
                text-decoration: none;
                border: 2px solid #B55348;
                color: #B55348;
                padding-left: 10px;
            }
            .ojo{
                position: absolute;
                right: 15px; bottom: 20px;
                width: 16px; object-fit: contain;
                cursor: pointer;
            }
            .ojo-error{
                position: absolute;
                right: 15px; bottom: 57px;
                width: 16px; object-fit: contain;
                cursor: pointer;
            }
            .ojo-confirm-error{
                position: absolute;
                right: 15px; bottom: 41px;
                width: 16px; object-fit: contain;
                cursor: pointer;
            }
        }

        .boton-continuar{
            width: 408px; height: 50px;
            margin-top: 24px;
        }

        .olvidar-pass{
            font-size: 16px;
            color: #B7EB06;
            font-weight: 700;
            cursor: pointer;
        }
        
    }

   
}