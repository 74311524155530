.loader {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    width: 100vw;
    height: 100vh;
}

.imgLogo {
    width: 200px;
    object-fit: contain;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: fade 1.5s ease-in-out infinite;
    @keyframes fade {
        0%,
        100% {
            opacity: 0;
        }

        50% {
            opacity: 1;
        }
    }
    
}