.general-body-kyc{
    width: 100%; height: 100vh;
    overflow-x: hidden;
    background-color: #0D2338;

    header{
        width: 100vw;
        height: 70px;
        display: flex; flex-direction: row; justify-content: space-between; align-items: center;
        z-index: 100;
        .img-logo{
            height: 56px;
            object-fit: contain;
            margin: 20px;
            cursor: pointer;
        }
    
        .img-arrow{
            height: 45px;
            object-fit: contain;
            cursor: pointer;
        }

        svg{
            cursor: pointer;
        }
    }

    
    .pasos{
        margin: 20px 5% 20px 10%;
        .fila-info-inselected{
            display: flex; flex-direction: row; justify-content: flex-start; align-items: center;
            cursor: pointer;
            padding: 3px;
            border: 2px solid white;
            border-radius: 6px;
            margin: 25px;
            height: 77px;
        }
        .fila-info-selected{
            display: flex; flex-direction: row; justify-content: flex-start; align-items: center;
            cursor: pointer;
            padding: 3px;
            border: 2px solid #B7EB06;
            border-radius: 6px;
            margin: 25px;
            height: 77px;
        }
    
        .linea-selected{
            background-color:#B7EB06;
        }
    
        .linea-inselected{
            background-color: white;
        }
    
        .linea-horizontal-izq{
            width: 32px;
            height: 2px;
            
    
            position: absolute;
            top: 42px;
            left: -5px;
        }
        .linea-vertical-izq-abajo{
            width: 2px;
            height: 58px;
    
            position: absolute;
            top: 42px;
            left: -5px;
        }
        .linea-vertical-izq-arriba{
            width: 2px;
            height: 57px;
    
            position: absolute;
            top: -12px;
            left: -5px;
        }
        .linea-horizontal-der{
            width: 32px;
            height: 2px;
    
            position: absolute;
            top: 42px;
            right: -5px;
        }
        .linea-vertical-der-abajo{
            width: 2px;
            height: 58px;
    
            position: absolute;
            top: 42px;
            right: -5px;
        }
        .linea-vertical-der-arriba{
            width: 2px;
            height: 57px;
    
            position: absolute;
            top: -12px;
            right: -5px;
        }

        .img-check{
            width: 30px; height: 30px;
            margin: 8px;
        }
        .numero-info-inselected{
            color: white;
            border-radius: 100px;
            border: 1px solid white;
            font-size: 17px;
            width: 30px; height: 30px;
            text-align: center;
            font-weight: bold;
            margin: 8px;
            display: flex; flex-direction: row; justify-content: center; align-items: center;
        }
        .numero-info-selected{
            background-color: #B7EB06;
            border: 1px solid white;
            color: #0D2338;
            border-radius: 100px;
            font-size: 17px;
            width: 30px; height: 30px;
            text-align: center;
            font-weight: bold;
            margin: 8px;
            display: flex; flex-direction: row; justify-content: center; align-items: center;
        }
        .texto-info{
            color: white;
            font-size: 16px;
            font-weight: 700px;
            width: 204px;
        }

        .verde{
            color: #B7EB06 !important;
        }
        
    }

    .punto-step2 {
        width: 20px;
        height: 20px;
        margin: 5px;
        background-color: #B7EB06; 
        border-radius: 50%;
    }
    
    .titulo-1{
        font-size: 24px;
        font-weight: bold;
    }
    
    .scroll-custom{
        &::-webkit-scrollbar{
            -webkit-appearance: none ! important;
        }
        
        
        &::-webkit-scrollbar:vertical {
            width: 8px ! important;
        }
        
        &::-webkit-scrollbar-button:increment,*::-webkit-scrollbar-button {
            display: none ! important;
        } 
        
        &::-webkit-scrollbar:horizontal {
            height: 5px ! important;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: #FBFBFB ! important;
            border-radius: 100px ! important;
            border: 2px solid #FBFBFB ! important;
        }
        
        &::-webkit-scrollbar-track {
            border-radius: 10px ! important;  
        }
    }
    
    .espacio-input{
        display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;
        position: relative;
        margin: 0px 10px;
        width: 260px;
        p{
            margin-bottom: 3px !important;
        }
        .titulo-2{
            font-size: 18px;
            width: 260px;
        }
        .input{
            width: 260px !important; height: 50px;
            border-radius: 8px;
            outline: none;
            text-decoration: none;
            border: none; padding-left: 10px;
        }
        .input-select{
            width: 272px !important; height: 50px;
            border-radius: 8px;
            outline: none;
            text-decoration: none;
            border: none; padding-left: 10px;
        }
    
        .input-error{
            width: 398px; height: 50px;
            border-radius: 8px;
            outline: none;
            text-decoration: none;
            border: 2px solid #B55348;
            padding-left: 10px;
        }
    
        .texto-error{
            font-size: 14px;
            color: #B55348;
            width: 398px;
            margin-top: 0px;
        }
    
        .ojo{
            position: absolute;
            right: 15px; bottom: 20px;
            width: 16px; object-fit: contain;
            cursor: pointer;
        }
    
        .ojo-error{
            position: absolute;
            right: 15px; bottom: 55px;
            width: 16px; object-fit: contain;
            cursor: pointer;
        }
        .ojo-confirm-error{
            position: absolute;
            right: 15px; bottom: 41px;
            width: 16px; object-fit: contain;
            cursor: pointer;
        }
    }
    
    .boton-continuar{
        width: 570px; height: 42px;
    }
    
    .boton-resultados{
        width: 570px; height: 42px;
    }

    .boton-metamap{
        width: 100%; height: 42px;
    }


    .linea-seleccion{
        border-bottom: 2px solid #B7EB06;
    }

    

    .punto {
        width: 5px;
        height: 5px;
        background-color: #B7EB06; 
        border-radius: 50%;
    }

    .boton-documentos{
        display: flex;
        width: 250px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: #0D2338;
        border-radius: 8px;
        border: 1px solid var(--light, #FBFBFB);
        color: var(--light, #FBFBFB);
        font-size: 18px;
        font-weight: 700;
        margin: 20px 20px 20px 0px;
        cursor: pointer;

    }

    .boton-foto{
        display: flex;
        width: 250px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        background-color: #FBFBFB;
        border-radius: 8px;
        border: 1px solid var(--light, #FBFBFB);
        color: var(--light, #0D2338);
        font-size: 18px;
        font-weight: 700;
        margin: 20px 25px 20px 0px;
        cursor: pointer;

        p{
            margin-left: -25px;
        }

    }

    .foto-img{
        position: absolute;
        width: 25px;
        object-fit: contain;
        top: 11px;
        right: 60px;
    }

    .upload-img{
        position: absolute;
        width: 25px;
        object-fit: contain;
        top: 30px;
    }

    .frontal{
        right: 60px;
    }

    .posterior{
        right: 50px;
    }

    .linea{
        width: 100%;
        height: 1px;
        background-color: #FFF;
        margin: 20px 0px;
    }
}

.modal-foto{
    display: flex; flex-direction: column; justify-content: center; align-items: center;
    width: 70vw;
    height: 90vh;

    .tomar-foto{
        width: 200px;
        border-radius: 8px;
        background-color: #FBFBFB;
        color: #0D2338;
        font-weight: bold;
        padding: 10px;
        margin: 20px;
        cursor: pointer;
    }
}

.error-date {
    font-size: 14px;
    margin-top: 8px;
    color: #ab5046;
}

.margin-top-custom {
    margin-top: 30px !important;
}

.calendario-input {
    height: 50px;
    border-radius: 8px;
    outline: none;
    text-decoration: none;
    position: relative;
    font-size: 13px;
    cursor: pointer;
    background-color: #fff;
    width: 100%;
    width: 272px;
    .react-date-picker__wrapper {
        border: none;
    }
    .react-date-picker__calendar--open {
        height: 293px !important;
    }
    .react-date-picker__inputGroup__input,
    .react-date-picker__inputGroup__divider,
    .react-date-picker__inputGroup__leadingZero {
        color: black;
    }
  }