.general-body-sign-up{
    width: 100%; height: 100vh;
    overflow-x: hidden;
    background-color: #0D2338;
    display: flex; flex-direction: row; justify-content: center; align-items: center;
    position: relative;

    header{
        position: absolute;
        top: 0; left: 0;
        z-index: 2;
        width: 100vw;
        height: 70px;
        display: flex; flex-direction: row; justify-content: flex-end; align-items: center;
    
    
        .img-arrow{
            height: 45px;
            object-fit: contain;
            cursor: pointer;
        }
        svg{
            cursor: pointer;
        }
    }
}



.componente-mitad{
    width: 45vw; 
    height: 100vh;
    position: relative;
    overflow-x: hidden;
    

    
    .formulario-registro{
        height: 100vh;
        .titulo-2{
            font-size: 18px;
            width: 400px;
        }

        .espacio-titulo{
            width: 100%;
            .titulo-img { width: 183px; height: 63px; }
            .logo-img { width: 72px; height: 63px;}
            margin-top: 10px;
        }

        .scroll-custom{
            &::-webkit-scrollbar-thumb {
                background-color: #B4BBC1 ! important;
                border-radius: 100px ! important;
                border: 1px solid #B4BBC1 ! important;
            }
        }

        .espacio-formulario{
            max-height: 80vh;
            overflow-y: auto;
            margin-bottom: 10px;

            .espacio-inputs{
                padding-right: 10px;
                .espacio-input{
                    position: relative;
                    p { margin-bottom: 3px !important; }
                    
                    .input{
                        width: 400px; height: 50px;
                        border-radius: 8px;
                        outline: none;
                        text-decoration: none;
                        border: none; padding-left: 10px;
                    }
    
                    .input-error{
                        width: 398px; height: 50px;
                        border-radius: 8px;
                        outline: none;
                        text-decoration: none;
                        border: 2px solid #B55348;
                        padding-left: 10px;
                    }
    
                    .texto-error{
                        font-size: 14px;
                        color: #B55348;
                        width: 398px;
                        margin-top: 0px;
                    }
    
                    .ojo{
                        position: absolute;
                        right: 15px; bottom: 20px;
                        width: 16px; object-fit: contain;
                        cursor: pointer;
                    }
    
                    .ojo-error{
                        position: absolute;
                        right: 15px; bottom: 55px;
                        width: 16px; object-fit: contain;
                        cursor: pointer;
                    }
                    .ojo-confirm-error{
                        position: absolute;
                        right: 15px; bottom: 41px;
                        width: 16px; object-fit: contain;
                        cursor: pointer;
                    }
                }
            }

            
            .boton-continuar{
                width: 408px; height: 42px;
            }
            
    
            .link p{
                font-size: 16px;
                color: #B7EB06;
                font-weight: 700;
                cursor: pointer;
                text-decoration: none;
                margin-top: 5px;
            }
    
            .link:hover p{
                text-decoration: none;
                color: #B7EB06;
            }
            
        }

        
        
    }

    .formulario-codigo{
        height: 100vh;

        .titulo-2{
            font-size: 18px;
            width: 400px;
            margin-left: 10px;
        }

        .texto-error{
            font-size: 14px;
            color: #B55348;
            margin-left: 10px;
        }

        .espacio-titulo{
            display: flex; flex-direction: row; justify-content: flex-start; align-items: flex-start;
            width: 100%;

            .titulo-img{
                width: 183px; height: 63px;
            }
            .logo-img{
                width: 72px; height: 63px;
            }
            
        }

        .espacio-input-codigo{
            display: flex; flex-direction: row; justify-content: center; align-items: center;
            position: relative;
            p{
                margin-bottom: 3px !important;
            }
            .input{
                width: 72px; height: 72px;
                border-radius: 8px;
                outline: none;
                text-decoration: none;
                font-size: 32px;
                border: none; text-align: center;
                margin: 10px;
            }
            .input-error{
                width: 70px; height: 72px;
                border-radius: 8px;
                outline: none;
                text-decoration: none;
                font-size: 32px;
                border: 2px solid #B55348; text-align: center;
                margin: 10px;
            }
            .input-success{
                width: 68px; height: 72px;
                border-radius: 8px;
                outline: none;
                text-decoration: none;
                font-size: 32px;
                border: 2px solid #B7EB06; text-align: center;
                margin: 10px;
            }
        }

        .boton-continuar{
            width: 460px; height: 50px;
            margin-top: 24px;
            margin-left: 10px;
        }

        .boton-reenviar-codigo{
            width: 460px; height: 50px;
            background-color: #FBFBFB;
            color: #0D2338;
            font-weight: bold;
            font-size: 16px;
            margin-top: 24px;
            margin-left: 10px;
            outline: none;
            text-decoration: none;
            border-radius: 8px;
            padding: 0; border: none;
            cursor: pointer;
        }
    }

    
}



.modal-terminos{
    display: flex; flex-direction: row; justify-content: flex-start; align-items: flex-start;
    margin: 0;
    
    .menu{
        width: 272px; height: 501px;
        background-color: #E7E9EB;
        padding-left: 25px;
        .fila-info{
            display: flex; flex-direction: row; justify-content: flex-start; align-items: center;
            cursor: pointer;
            margin: 10px 0px;
            .numero-info-inselected{
                color: #0D2338;
                border-radius: 100px;
                border: 1px solid #0D2338;
                font-size: 17px;
                width: 30px; height: 30px;
                text-align: center;
                font-weight: bold;
                margin: 8px;
                display: flex; flex-direction: row; justify-content: center; align-items: center;
            }
            .numero-info-selected{
                background-color: #0D2338;
                color: white;
                border-radius: 100px;
                font-size: 17px;
                width: 30px; height: 30px;
                text-align: center;
                font-weight: bold;
                margin: 8px;
                display: flex; flex-direction: row; justify-content: center; align-items: center;
            }
            .texto-info{
                color: #0D2338;
                font-size: 16px;
                font-weight: 700px;
                width: 204px;
            }
        }
    }

    .texto-terms{
        width: 805px; height: 501px;
        margin-left: 15px;
        .titulo{
            font-weight: 700;
            font-size: 20px;
            color: #0D2338;
            margin: 15px 0px;
        }

        .subtitulo-espacio{
            display: flex; flex-direction: row; justify-content: flex-start; align-items: center;
            width: 750px;
            margin: 15px 0px;
            .subtitulo{
                font-weight: 700;
                font-size: 16px;
                color: #000000;
                white-space: nowrap;
                
            }
            .linea{
                height: 1px;
                background-color: #000000;
                width: 100%;
                margin-left: 5px;
                margin-top: 5px;
            }
        }
        .parrafo{
            width: 785px;
            overflow-y: auto;
            margin-top: 0px;
            .texto-parrafo{
                color: #0D2338;
                font-size: 16px;
                margin-top: 0px;
                padding-right: 60px;
                text-align: justify;
            }
            &::-webkit-scrollbar{
                -webkit-appearance: none ! important;
            }
            
            
            &::-webkit-scrollbar:vertical {
                width: 8px ! important;
            }
            
            &::-webkit-scrollbar-button:increment,*::-webkit-scrollbar-button {
                display: none ! important;
            } 
            
            &::-webkit-scrollbar:horizontal {
                height: 5px ! important;
            }
            
            &::-webkit-scrollbar-thumb {
                background-color: #0D2338 ! important;
                border-radius: 100px ! important;
                border: 1px solid #0D2338 ! important;
            }
            
            &::-webkit-scrollbar-track {
                border-radius: 10px ! important;  
            }
        }

        
        .aceptar-terms{
            display: flex; flex-direction: row; justify-content: space-between; align-items: center;
            width: 740px;
            margin-top: 20px;
            margin-bottom: 15px;
            .checks{
                display: flex; flex-direction: column; justify-content: flex-start; align-items: center;
                margin-top: 5px;
                .linea-check{
                    position: relative;
                    display: flex; flex-direction: row; justify-content: flex-start; align-items: flex-start;
                    margin: 5px 0px;
                    width: 390px;
                    .check{
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 24px;
                        width: 24px;
                        z-index: 2;
                    }
                    .check-mark{
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 24px;
                        width: 24px;
                        background-color: #fff;
                        border-radius: 8px;
                        border: 1.5px solid #0D2338;
                        cursor: pointer;
                    }
                    input:checked ~ .check-mark{
                        background-color: #0D2338;
                    }
                    .check-mark:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }
                    input:checked ~ .check-mark:after {
                        display: block;
                      }
                    .check-mark:after {
                        left: 9px;
                        top: 5px;
                        width: 5px;
                        height: 10px;
                        border: solid white;
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                    .texto{
                        font-size: 16px;
                        margin-left: 35px;
                        margin-top: 2px;
                    }
                }
            }
            .boton-continuar{
                width: 256px; height: 50px;
                
                margin-left: 10px;
                margin-right: 20px;
                
            }
        }
    }
}

.formulario-pago{
    height: 100vh;
    margin-left: 10%;
    width: 440px;

    .espacio-titulo{
        display: flex; flex-direction: row; justify-content: flex-start; align-items: flex-start;
        width: 100%;
        margin: 10px 0px;
        .titulo-img{
            width: 183px; height: 63px;
        }

        .logo-img{
            width: 72px; height: 63px;
        }
        
    }

    .titulo-1{
        font-size: 24px;
        font-weight: bold;
        margin-left: 7px;
    }

    .titulo-2{
        font-size: 18px;
        margin-left: 7px;
    }

    .linea-check{
        position: relative;
        display: flex; flex-direction: row; justify-content: flex-start; align-items: flex-start;
        margin: 20px 0px;
        width: 390px;
        .check{
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 24px;
            width: 24px;
            z-index: 2;
        }
        .check-mark{
            position: absolute;
            top: 0;
            left: 0;
            height: 24px;
            width: 24px;
            background-color: #0D2338;
            border-radius: 8px;
            border: 1.5px solid #fff;
            cursor: pointer;
        }
        input:checked ~ .check-mark{
            background-color: #fff;
        }
        .check-mark:after {
            content: "";
            position: absolute;
            display: none;
        }
        input:checked ~ .check-mark:after {
            display: block;
            }
        .check-mark:after {
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid #0D2338;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
        .texto{
            font-size: 20px;
            margin-left: 35px;
            margin-top: 2px;
        }
    }
    .boton-continuar{
        width: 395px; height: 50px;
        margin-right: 20px;
    }
}

.modal-cuenta-creada{
    display: flex; flex-direction: column; justify-content: center; align-items: center;
    width: 405px;
    .fila-titulo{
        display: flex; flex-direction: row; justify-content: flex-start; align-items: center;
        width: 405px;
        .check-img{
            width: 19px;
            object-fit: contain;
            margin: 5px;
        }
    
        .titulo{
            font-size: 18px;
            font-weight: bold;
            margin: 5px 0px;
        }
    }

    .texto{
        font-size: 16px;
        margin: 10px 0px;
    }

    .boton-continuar{
        width: 405px; height: 50px;
        background-color: #B7EB06;
        margin-left: 10px;
        margin-right: 20px;
    }

    .boton-comenzar{
        width: 197px; height: 50px;
        background-color: #B7EB06;
        color: #0D2338;
        font-weight: bold;
        font-size: 16px;
        margin: 10px;
        outline: none;
        text-decoration: none;
        border-radius: 8px;
        padding: 0; border: none;
        cursor: pointer;
    }

    .boton-cancelar{
        width: 197px; height: 50px;
        background-color: #FBFBFB;
        color: #0D2338;
        font-weight: bold;
        font-size: 16px;
        margin: 10px;
        outline: none;
        text-decoration: none;
        border-radius: 8px;
        border: 1px solid black;
        padding: 0;
        cursor: pointer;
    }

    .boton-coinpayments{
        position: relative;
        border: 1px solid black;
        border-radius: 8px;
        width: 289px;
        margin: 20px;
        cursor: pointer;
        .coinpayments-img{
            width: 18px;
            object-fit: contain;
            margin: 5px;
        }
    }
    .paypal-img{
        width: 412px;
        object-fit: contain;
        margin: 5px 15px;
        cursor: pointer;
    }
}


